import React from 'react';
import Logowanie from './strona/logowanie.js';
import Nav from './strona/nav.js';
import Glowna from './strona/glowna.js';
import Profil from './strona/profil.js';
import Gielda from './strona/gielda.js';
import Rejestracja from './strona/rejestracja.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Logowanie />} ></Route>
        <Route path="rejestracja" element={<Rejestracja />}></Route>

        <Route path="nav" element={<Nav />}>
        <Route path="glowna" element={<Glowna />} ></Route>
        <Route path="profil" element={<Profil />} ></Route>
        <Route path="gielda" element={<Gielda />} ></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
