import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
function  Profil(){
  return (
    <div class="all">
    <main>
        <div class="container">
            <h1 class="text-center">Profil użytkownika</h1>
            <h4 class="text-start">Dane osobowe:</h4>
            <h5>Imie: (imie)</h5>
            <h5>Nazwisko: (nazwisko)</h5>
            <h5>Pesel: (pesel)</h5>
            <h5>Data urodzenia: (data-urodzenia)</h5>
            <h5>E-mail: (email)</h5>
            <h5>Ilość lokat:</h5>
            <h5>Ilość kont:</h5>
        </div>
      </main>
    
    </div>
  );
}

export default Profil;