import 'bootstrap/dist/css/bootstrap.css';
import React, { useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';


const Reje = () => {

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [pesel, setPesel] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
    const [err, setErr] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        
        if (!name || !surname || !pesel || !email || !password || !repassword) {
            setErr('Wszystkie pola są wymagane');
            return;
        }

        
        if (pesel.length !== 11) {
            setErr('Pesel musi mieć 11 cyfr');
            return;
        }

        
        if (password !== repassword) {
            setErr('Hasła się nie zgadzają');
            return;
        }

       
        setErr('');

        
        axios.post('http://localhost:3002/rejestracja', { name, surname, pesel, email, password })
        .then((response) => {
          setErr('Rejestracja pomyślna');
        })
        .catch((error) => {
          if (error.response && error.response.data.error) {
            setErr(error.response.data.error);
          } else {
            setErr('Błąd podczas rejestracji');
          }
        });
    };
    

    return(
        <div>
            <header class="bg-success text-white">
                <h1 class="text-center">Pro-Bank</h1>
            </header>  
            <h1>Zarejestruj się</h1>
            <form class="row g-3" onSubmit={handleSubmit}>
                <div class="col-md-6">
                    <label for="name" class="form-label">Imie:</label>
                    <input value={name} type="text" class="form-control" id="name" onChange={e => setName(e.target.value)}></input>
                </div>
                <div class="col-md-6">
                    <label for="surname" class="form-label">Nazwisko:</label>
                    <input value={surname} type="text" class="form-control" id="surname" onChange={e => setSurname(e.target.value)}></input>
                </div>
                <div class="col-md-6">
                    <label for="pesel" class="form-label">Pesel:</label>
                    <input value={pesel} type="number" class="form-control" id="pesel" onChange={e => setPesel(e.target.value)}></input>
                </div>
                <div class="col-md-6">
                    <label for="email" class="form-label">Email</label>
                    <input value={email} type="email" class="form-control" id="email" onChange={e => setEmail(e.target.value)}></input>
                </div>
                <div class="col-md-6">
                    <label for="password" class="form-label">Hasło</label>
                    <input value={password} type="password" class="form-control" id="password" onChange={e => setPassword(e.target.value)}></input>
                </div>
                <div class="col-md-6">
                    <label for="repassword" class="form-label">Powtórz hasło:</label>
                    <input value={repassword} type="password" class="form-control" id="repassword" onChange={e => setRepassword(e.target.value)}></input>
                </div>
                
                
                <div class="col-12">
                    <button type="submit" class="btn btn-primary">Załóż konto</button>
                    <Link to="/" ><button class="mx-2 btn btn-primary">Przejdź do logowania</button></Link>
                </div>
            </form>
            <pre>{err}</pre>

            <footer class="container-fluid text-start bg-success text-white text-center position-absolute bottom-0">
                <p>Stronę wykonali: Paweł Jeliński, Bartłomiej Białk, Noah Szargiej, Krzysztof Puzdrowski, Dominik Stenzel, Grzegorz Rogocki, Wojciech Dutko</p>
                <p>Praktyki  &copy; AMW</p>
            </footer>
        </div>
    );
}

export default Reje