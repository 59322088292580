import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './nav.js';
import logo from '../image/logo.jpg';
import React, { useState } from 'react';
import axios from 'axios';


const Logowanie = () => {
  const [email, setEmail] = useState('');
  const [pesel, setPesel] = useState('');
  const [haslo, setHaslo] = useState('');
  const [err, setErr] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Sprawdzenie czy wszystkie pola są wypełnione
    if (!email || !pesel || !haslo) {
      setErr('Wszystkie pola muszą być wypełnione.');
      return;
    }

    // Wysłanie danych logowania do serwera
    axios.post('http://localhost:3002/logowanie', { email, pesel, haslo }) 
      .then((response) => {
        setErr('Logowanie pomyślne');
        window.location.href = "nav";
      })
      .catch((error) => {
        if (error.response && error.response.data.error) {
          setErr(error.response.data.error);
        } else {
          setErr('Błąd podczas logowania');
        }
      });
  };

  return (
    <div className="all">
      <header className="bg-success text-white">
        <h1 className="text-center">Pro-Bank</h1>
      </header>  

      <main>
        <div className="d-flex flex-column">
          <div className="container d-grid align-items-center d-flex flex-column">
            <div className="row text-center">
              <h2>Logowanie do Pro-Banku</h2>
            </div>
            
            <form onSubmit={handleSubmit}>
              <div className="row g-2">
                <div className="col">
                  <h4>E-mail:</h4>
                  <input 
                    type="text" 
                    id="email" 
                    className="mb" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                  />
                  
                  <h4>Pesel:</h4>
                  <input 
                    type="text" 
                    id="pesel" 
                    value={pesel} 
                    onChange={(e) => setPesel(e.target.value)} 
                  />

                  <h4>Hasło:</h4>
                  <input 
                    type="password" 
                    id="haslo" 
                    value={haslo} 
                    onChange={(e) => setHaslo(e.target.value)} 
                  />
                </div>
               
                <div class="justify-content-start order-last col-sm-4 py-3">
                  <img src={logo} alt='Logo Banku' class="img-thumbnail"></img>
                </div>
              </div>

              <div className="mx-2 my-2">
                <button type="submit" className="btn btn-primary">Zaloguj</button>
              </div>

              {err && <div className="text-danger mt-3">{err}</div>}
            </form>

            <Link to="/rejestracja">Nie masz konta? Zarejestruj się</Link>
          </div>
        </div>
      </main>

     
        <footer class="container-fluid text-start bg-success text-white text-center position-absolute bottom-0">
            <p>Stronę wykonali: Paweł Jeliński, Bartłomiej Białk, Noah Szargiej, Krzysztof Puzdrowski, Dominik Stenzel, Grzegorz Rogocki, Wojciech Dutko</p>
            <p>Praktyki  &copy; AMW</p>
        </footer>
    </div>
  );
};

export default Logowanie;

