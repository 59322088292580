import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
function  Gielda(){
  return (
    <div class="all">

    
      <main>
        <div class="container">
            <h1>Giełda</h1>
        </div>
      </main>
    
    </div>

  );
}

export default Gielda;