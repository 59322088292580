import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import osoba from '../image/osoba.png';
const  Glowna = () => {
  return (
    <div class="all">
  
      <main>
        <div class="d-flex container flex-row align-items-center">
          <div class="d-flex col mx-6 col-sm order-last justify-content-end ">
              <img src={osoba} alt='foto'></img>
          </div>
          <div class="">
            <h2 class="text-center">Konto:</h2>
            <h4>(Imie i nazwisko)</h4>
            <h4>Numer konta: (numer)</h4>
            <h4>Saldo konta:(saldo) PLN</h4>
            <h4>Historia transakcji: (historia)</h4>
            <h4>Limity</h4>
            <h4>Przelewy</h4>
            <h4>Faktury</h4>
            <h4>Załóż nowe konto</h4>
          </div>
        </div>
        
      </main>
    
    </div>
  );
}

export default Glowna;