import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import Glowna from '../strona/glowna.js';
import Profil from '../strona/profil.js';
import Gielda from '../strona/gielda.js';

const  Navv = () => {
  return (
    <div class="all">
      <header class="bg-success text-white">
        <p class="text-center fs-1 fw-bold my-0">Pro-Bank</p>
      </header>

      <div class=" d-flex flex-column  ">
        <nav class="nav d-flex flex-row bg-secondary-subtle justify-content-center">
          <Link to="glowna" class="nav-link "><button>Główna</button></Link>
          <Link to="profil" class="nav-link" ><button>Profil użytkownika</button></Link>
          <Link to="gielda" class="nav-link "><button>Giełda</button></Link>
          <div class="d-flex col justify-content-end">
            <p>(czas do wylogowania)</p>
            <Link to="/" class=" nav-link justify-content-end"><button class="bg-danger text-white">Wyloguj się</button></Link>
          </div>
          
          </nav>
      </div>
      

      <main>
        <Routes>
          <Route index="/glowna" element={<Glowna />} />
          <Route path="/glowna" element={<Glowna />} />
          <Route path="/profil" element={<Profil />}/>
          <Route path="/gielda" element={<Gielda />}/>
        </Routes>
      </main>
      <footer class="container-fluid text-start bg-success text-white text-center position-absolute bottom-0">
            <p>Stronę wykonali: Paweł Jeliński, Bartłomiej Białk, Noah Szargiej, Krzysztof Puzdrowski, Dominik Stenzel, Grzegorz Rogocki, Wojciech Dutko</p>
            <p>Praktyki  &copy; AMW</p>
        </footer>
    </div>
  );
}

export default Navv;